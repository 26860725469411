var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popover",
    {
      staticClass: "video-popover",
      attrs: { name: _vm.name, event: "click", transition: "fade" },
    },
    [
      _c("custom-video-player", {
        attrs: { src: _vm.src, poster: _vm.preview },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }