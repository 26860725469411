@@ -1,335 +0,0 @@
<template>
    <div class="character-level">
        <div class="character-level">
            <div class="character-level__descriptions">
                <div class="character-level--attribute-color">
                    <span class="character-level__descriptions-number">{{ level }}</span>
                    <span>LVL</span>
                </div>

                <div class="character-level__requires">
                    <span>Requires</span>
                    <span class="character-level--action-color">{{current}}/{{ requires }} exp</span>
                </div>
            </div>

            <progress-bar class="character-level__bar" :percent="percent" />

            <div v-if="points" class="character-level__points">
                <span class="character-level__points-text">You have</span>
                <span class="character-level__points-count">{{points}} EXP POINTS</span>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from './progress-bar.vue';
export default {
  name: "CharacterLevel",
  props: {
    requires: { type: Number, default: 0 },
    level: { type: Number, default: 1 },
    current: { type: Number, default: 0 },
    points: { type: Number, default: 0 },
  },
  components: {
    ProgressBar,
  },
  computed: {
      percent() {
        return this.current / this.requires * 100;
      },
  }
}
</script>

<style lang="scss" scoped>

.character-level {
    align-items: center;
    padding-top: 1em;
}

.character-level__descriptions {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
    align-items: flex-end;
    line-height: 1em;

    &-number {
        font-weight: 600;
        font-size: 32px;
        padding-right: 5px;
    }
}

.character-level__requires {
    display: flex;
    gap: 0.5em;
    font-size: 14px;
}

.character-level__points {
    padding: 5px 60px 5px 25px;
    background: linear-gradient(270deg, rgba(6, 190, 144, 0.5) 5%, rgba(6, 190, 144, 0) 100%);
    transform: skewX(-6deg);
    display: inline-flex;
    gap: 0.5em;
    font: 500 14px "Tektur";
    text-transform: initial;

    &-text {
        color: #BFBFBF;
    }
    &-count {
        color: #FFC010;
    }
}

.character-level--attribute-color {
    color: #06BE90;
}

.character-level--action-color {
    color: #ffcc13;
}

.character-level__bar {
    height: 10px;
    background-color: #6B7B98;
}

</style>
