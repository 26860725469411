var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "custom-video-player",
      style: { minHeight: `${_vm.getPlayerOptions.height}px` },
    },
    [
      _c("video-player", {
        ref: "videoPlayer",
        staticClass: "custom-video-player__video",
        attrs: { options: _vm.getPlayerOptions, playsinline: true },
        on: { play: _vm.onPlayerPlayed, ready: _vm.playerReadied },
      }),
      _vm.canShowPlayButton
        ? _c(
            "div",
            { staticClass: "custom-video-player__buttons" },
            [
              _c("icon-btn", {
                staticClass: "youtube-video__btn",
                attrs: { size: 44 },
                on: { click: _vm.onPlay },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }