<template>
  <div
      :class="['content', { 'content--grid': canShowAside }]"
  >
    <aside v-if="canShowAside" class="content__aside">
      <h3 v-if="asideCaption" class="content__aside-caption">{{ asideCaption }}</h3>

      <slot name="aside" />
    </aside>
    <main :class="['content__main', { 'content__main--pure': !canShowAside }]">
      <slot name="content" />
    </main>
  </div>
</template>

<script>
export default {
  name: "ContentCharacter",
  props: {
    asideCaption: { type: String, default: '' },
  },
  computed: {
    canShowAside() {
      return this.$slots.aside
    },
  },
}
</script>

<style lang="scss" scoped>
.market-place {
    background: linear-gradient(180deg, #1F213A 0%, #000000 100%), #1F213A;
    min-height: 74vh;
}
.content {
  flex-grow: 1;

  &--grid {
    display: grid;
    grid-template-columns: 296px 1fr;
  }
}
.content__aside {
  background-color: #232323;
  color: #fff;
}
.content__aside-caption {
  padding: 20px 45px;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 19px;

  background-color: #4F4F4F;
}
//.content__main {
//  padding: 33px 64px;
//  padding-right: 15px;
//}

.content__main--pure {
  max-width: 1650px;
  padding: 35px 15px;
  margin: auto;
}

@media screen and (max-width: $bp_mb) {
  .content__main {
    padding-right: 15px;
    padding-left: 15px;
  }
}

</style>
