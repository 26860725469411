var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "entity-color" },
    [
      _c("BaseIcon", {
        style: { color: _vm.color, "min-width": "42px" },
        attrs: { name: _vm.title },
      }),
      _c("div", { staticClass: "entity-color__description" }, [
        _c("span", { staticClass: "entity-color__description-title" }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _c("div", { staticClass: "entity-color__description-text" }, [
          _vm._v(_vm._s(_vm.text)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }