<template>
  <div class="custom-video-player" :style="{minHeight: `${getPlayerOptions.height}px`}">
    <video-player
      class="custom-video-player__video"
      ref="videoPlayer"
      :options="getPlayerOptions"
      :playsinline="true"
      @play="onPlayerPlayed"
      @ready="playerReadied"
    />
    <div v-if="canShowPlayButton" class="custom-video-player__buttons">
      <icon-btn
        class="youtube-video__btn"
        @click="onPlay"
        :size="44"
      />
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'
import IconBtn from './icon-btn.vue'

export default {
  name: "CustomVideoPlayer",
  props: {
    src: { type: String, default: '' },
    poster: { type: String, default: '' },
  },
  components: {
    videoPlayer,
    IconBtn
  },
  data() {
    return {
      ready: false,
      played: false,
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },

    canShowPlayButton() {
      return this.ready && !this.played
    },

    getPlayerOptions() {
      return {
        height: '262',
        autoplay: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [ this.src ],
        poster: this.poster,
      }
    },
  },
  methods: {
    playerReadied() {
      this.ready = true
    },

    onPlay() {
      this.player.play()
      this.played = true
    },

    onPlayerPlayed() {
      this.played = true
    },
  }
}
</script>

<style>

.custom-video-player {
  position: relative;
  background-color: black;
}

.custom-video-player__buttons {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* video player styles */

.vjs_video_3-dimensions,
.video-js {
  width: auto;
  /* height: auto; */
}

.video-js .vjs-tech {
  position: static;
}

.video-js .vjs-big-play-button {
  display: none;
}

</style>
