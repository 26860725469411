var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "character-level" }, [
    _c(
      "div",
      { staticClass: "character-level" },
      [
        _c("div", { staticClass: "character-level__descriptions" }, [
          _c("div", { staticClass: "character-level--attribute-color" }, [
            _c(
              "span",
              { staticClass: "character-level__descriptions-number" },
              [_vm._v(_vm._s(_vm.level))]
            ),
            _c("span", [_vm._v("LVL")]),
          ]),
          _c("div", { staticClass: "character-level__requires" }, [
            _c("span", [_vm._v("Requires")]),
            _c("span", { staticClass: "character-level--action-color" }, [
              _vm._v(_vm._s(_vm.current) + "/" + _vm._s(_vm.requires) + " exp"),
            ]),
          ]),
        ]),
        _c("progress-bar", {
          staticClass: "character-level__bar",
          attrs: { percent: _vm.percent },
        }),
        _vm.points
          ? _c("div", { staticClass: "character-level__points" }, [
              _c("span", { staticClass: "character-level__points-text" }, [
                _vm._v("You have"),
              ]),
              _c("span", { staticClass: "character-level__points-count" }, [
                _vm._v(_vm._s(_vm.points) + " EXP POINTS"),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }