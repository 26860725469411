<template>
    <popover :name="name" event="click" class="video-popover" transition="fade">
        <custom-video-player
            :src="src"
            :poster="preview"
        />
    </popover>
</template>

<script>
import CustomVideoPlayer from '@/components/common/custom-video-player.vue'

export default {
    name: 'VideoPopover',
    props: {
        name: { type: String, required: true },
        src: { type: String, default: '' },
        preview: {type: String, default: ''},
    },
    components: {
        CustomVideoPlayer,
    }
}
</script>

<style lang="scss" scoped>
    .video-popover {
        width: 50%!important;
        min-width: 320px;
        max-width: 500px;
        border: 5px solid #0e0f19;
        box-shadow: 0px 10px 10px #000000ab;
        padding: 0;

        &:before {
            border-bottom-color: #0e0f19;
            top: -11px;
        }
    }
</style>
