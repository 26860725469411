var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "characteristic" }, [
    _c(
      "div",
      { staticClass: "characteristic__icon" },
      [_c("BaseIcon", { attrs: { name: _vm.title } })],
      1
    ),
    _c("div", { staticClass: "characteristic__title" }, [
      _vm._v(_vm._s(_vm.getTitle(_vm.title))),
    ]),
    _c(
      "div",
      { staticClass: "characteristic__bottom" },
      [
        _c("ProgressBarBlocks", {
          attrs: { count: _vm.number, additionalBlocks: _vm.pointsAvailable },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "characteristic__level" }, [
      _vm._v(_vm._s(_vm.number)),
    ]),
    _vm.pointsAvailable
      ? _c("div", {
          staticClass: "characteristic__up",
          on: {
            click: function ($event) {
              return _vm.$emit("statsLevelUp", _vm.title)
            },
          },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }