<template>
  <div>
    <!-- <popover name="triangle_power-1" event="hover">
        The superpower will soon be available for purchase in the Staking section.
    </popover>
    <popover name="triangle_power-2" event="hover">
        The skill will soon be available for purchase in the Staking section.
    </popover> -->
  </div>
</template>

<script>
export default {
  name: 'Superpowers',
};
</script>
