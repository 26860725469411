<template>
  <div class="characteristic">
    <div class="characteristic__icon">
        <BaseIcon :name="title"/>
    </div>
    <div class="characteristic__title">{{ getTitle(title) }}</div>
    <div class="characteristic__bottom">
      <ProgressBarBlocks :count="number" :additionalBlocks="pointsAvailable"/>
    </div>
    <div class="characteristic__level">{{number}}</div>
    <div 
        v-if="pointsAvailable"
        class="characteristic__up"
        @click="$emit('statsLevelUp', title)"
        >
    </div>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
import ProgressBarBlocks from './progress-bar-blocks.vue'

export default {
    name: "Characteristic",
    components: {
        ProgressBarBlocks,
    },
    props: {
        title: { type: String, default: '' },
        number: { type: [String, Number], default: 0 },
        pointsAvailable: { type: Number, default: 0},
    },
    computed: {
        ...mapGetters([
          'GET_IS_DEVELOPMENT'
        ]),
        canShowAside() {
            return this.$slots.aside
        },
    },
    methods: {
        getTitle(title) {
            switch (title) {
                case 'atk': {
                    return 'attack';
                }
                case 'def': {
                    return 'defence';
                }
                case 'spd': {
                    return 'speed';
                }
                case 'stm': {
                    return 'stamina';
                }
                default: {
                    return title;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>

.characteristic {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.characteristic__title {
  min-width: 76px;
  padding-left: 5px;

  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.characteristic__bottom {
  min-height: 43px;
  display: flex;
  align-items: center;
  width: 100%;
}

.characteristic__up {
    border-radius: 20px;
    background: #FF9509;
    min-width: 17px;
    min-height: 17px;
    text-align: center;
    position: relative;
    cursor: pointer;

    &:after {
        position: absolute;
        content: "+";
        left: 16%;
        top: -3px;
        color: black;
        font-size: 18px;
        font-weight: 600;
    }
}

.characteristic__level {
    min-width: 20px;
}

</style>
