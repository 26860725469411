<template>
  <div class="progress-bar">
    <div
      class="progress-bar__progress"
      :style="{
        width: percent + '%',
      }"
    />
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    percent: { type: [String, Number], default: 0 },
  },
}
</script>

<style lang="scss" scoped>

.progress-bar {
  flex-grow: 1;
  background-color: #404040;
  height: 4px;
}

.progress-bar__progress {
    max-width: 100%;
    height: 100%;
    //background: linear-gradient(to top, #FFA50C, #FFCC13);
    clip-path: polygon(0 0, 100% 0, calc(100% - 3px) 100%, 0 100%);
    background: linear-gradient(to left, #1CF5BF -0.03%, #039B75 100%);
}

</style>
