// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".entity-color {\n  display: flex;\n  align-items: center;\n}\n.entity-color__figure {\n  position: relative;\n  width: 37px;\n  min-width: 37px;\n  height: 32px;\n}\n.entity-color__figure:after {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  top: 0;\n  background: linear-gradient(to top left, rgba(0, 0, 0, 0.25), transparent);\n}\n.entity-color__description {\n  margin-left: 9px;\n}\n.entity-color__description-title {\n  color: #BFBFBF;\n  font-size: 14px;\n  font-weight: 400;\n}\n.entity-color__description-text {\n  font-size: 14px;\n  text-transform: uppercase;\n  font-weight: 600;\n  margin-top: 0.2em;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
