var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progress-bar" }, [
    _c("div", {
      staticClass: "progress-bar__progress",
      style: {
        width: _vm.percent + "%",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }