<template>
    <div class="entity-color">
        <BaseIcon :style="{color, 'min-width': '42px'}" :name="title"/>
        <div class="entity-color__description">
            <span class="entity-color__description-title">{{ title }}</span>
            <div class="entity-color__description-text">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EntityColor",
    props: {
        title: { type: String, default: '' },
        text: { type: String, default: '' },
        color: { type: String, default: '' },
    },
}
</script>

<style lang="scss">

.entity-color {
  display: flex;
  align-items: center;
}
.entity-color__figure {
  position: relative;
  width: 37px;
  min-width: 37px;
  height: 32px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    background: linear-gradient(to top left, rgba(0, 0, 0, 0.25), transparent);
  }
}
.entity-color__description {
    margin-left: 9px;

    &-title {
        color: #BFBFBF;
        font-size: 14px;
        font-weight: 400;
    }
    &-text {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 0.2em;
    }

}

</style>
